import { apiHelper } from "./api_helper";

export const PHU_HUYNH_API_END_POINT = "phu-huynh";
export const PHU_HUYNH_UPATE_STATS_API_END_POINT = "phu-huynh/status";

export const phuHuynhApi = {
    SelectAll: (dm_coso_id, ts_phuhuynh_adm_status_id, dm_chiendich_id) =>
        apiHelper.get(`phu-huynh/coso/${dm_coso_id}/status/${ts_phuhuynh_adm_status_id ?? 0}/chiendich/${dm_chiendich_id ?? 0}`),
    SelectFamily: (id) => apiHelper.get(`phu-huynh/${id}/family`),
    SelectLichSuDangKy: (id, dm_coso_id) => apiHelper.get(`phu-huynh/${id}/coso/${dm_coso_id}/lich-su-dang-ky`),
    SelectStatusSummary: () => apiHelper.get('phu-huynh/status-summary'),
    SelectStatus: () => apiHelper.get('phu-huynh/status'),
    UpdateStatus: (data) => apiHelper.put('phu-huynh/status', data),
    UpdateTuVanVien: (data) => apiHelper.put('phu-huynh/tu-van-vien', data),
    Detail: (id) => apiHelper.get(`phu-huynh/${id}`),
    ValidateImport: (data) => apiHelper.put('phu-huynh/validate-import', data),
    Import: (data) => apiHelper.put('phu-huynh/import', data),
    UpdateInfo: (data) => apiHelper.put('phu-huynh', data),
    Insert: (data) => apiHelper.post('phu-huynh', data),
    Delete: (id) => apiHelper.delete(`${PHU_HUYNH_API_END_POINT}/${id}`),
    SelectByHocSinhQuanHeGiaDinh: (ts_hocsinh_id, ts_quanhegiadinh_id) =>
        apiHelper.get(`phu-huynh/hocsinh/${ts_hocsinh_id}/quanhegiadinh/${ts_quanhegiadinh_id}`),
}