import React, { useState, useEffect } from 'react';
import SelectBox from 'devextreme-react/select-box';
import { formEditorApi } from '../../../api/formEditorApi';
const FormCobobox = ({ value, dm_coso_id, ts_form_editor_id, parent_id, onValueChanged, disabled }) => {
  const [dataSource, setDataSource] = useState([]);
  useEffect(() => {
    handleReload();
  }, [parent_id]);
  const handleReload = async () => {
    const res = await formEditorApi.SelectDataSource(ts_form_editor_id, dm_coso_id, parent_id);
    if (res.is_success) {
      setDataSource(res.data);
      var vl = res.data.find(x => x.text == value);
      if(vl!=undefined && vl!= ""){
        value = vl.id;
        onValueChanged(value);
      }
    }
  };
  return (
    <SelectBox
      dataSource={dataSource}
      displayExpr={'text'}
      valueExpr={'id'}
      disabled={disabled}
      //   placeholder={translate("Chọn năm học")}
      value={value}
      stylingMode="underlined"
      width={'100%'}
      onValueChanged={(e) => {
        if (e.event && onValueChanged) {
          onValueChanged(e.value);
        }
      }}
    //   ref={cmb}
    //   onValueChanged={(e) => {
    //     onValueChanged(e.value);
    //   }}
    />
  );
};

export default FormCobobox;
