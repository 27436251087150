import { actionNameCreator } from "../state-helpers/actionTypeNameCreator";

const iniState = {
    isLoading: false,
    user: undefined
}
export const authActionTypes = {
    LOGIN: actionNameCreator.AUTH("LOGIN"),
    GET_INFO: actionNameCreator.AUTH("GET_INFO"),
    LOGOUT: actionNameCreator.AUTH("LOGOUT"),
}
export const authReducer = (state = iniState, action) => {

    switch (action.type) {
        case authActionTypes.LOGIN.START:
            return {
                ...state,
                isLoading: true,
                user: undefined
            }
        case authActionTypes.LOGIN.SUCCESS:
            authLocalStorageHeper.add(
                action.payload.token.access_token,
                action.payload.token.refresh_token
            );
            console.log({
                reducer: {
                    ...state,
                    isLoading: false,
                    user: action.payload.user_info
                }
            });
            return {
                ...state,
                isLoading: false,
                user: action.payload.user_info
            }
        case authActionTypes.LOGIN.ERROR:
            return {
                ...state,
                isLoading: false,
                user: undefined
            }
        case authActionTypes.GET_INFO.START:
            return {
                ...state,
                isLoading: true,
                user: undefined
            }

        case authActionTypes.GET_INFO.SUCCESS:
            return {
                ...state,
                isLoading: false,
                user: action.payload
            }
        case authActionTypes.GET_INFO.ERROR:
            return {
                ...state,
                isLoading: false,
                user: { id: 0 }
            }
        case authActionTypes.LOGOUT.START:
            authLocalStorageHeper.clear();
            return {
                ...state,
                isLoading: false,
                user: { id: 0 }
            }
        default:
            return state;
    }
}
export const authLocalStorageHeper = {
    add: (access_token, refresh_token) => {
        localStorage.access_token = access_token;
        localStorage.refresh_token = refresh_token;
    },
    clear: () => {
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
    }
}