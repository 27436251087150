import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { truongApi } from '../api/truongApi';
import { truongActionTypes } from '../state/reducers/truongReducer';
import { tsDictionaryApi } from '../api/tsDictionaryApi';
import notify from 'devextreme/ui/notify';
import useLanguage, { defaultLanguage } from '../hooks/use-language';

export const CommonContext = createContext(null);

export const HttpMethod = {
	GET: 'GET',
	POST: 'POST',
	PUT: 'PUT',
	DELETE: 'DELETE',
};

export default ({ children }) => {
	const dispatch = useDispatch();
	const { user } = useSelector((x) => x.auth);
	const [dictionary, setDictionary] = useState(new Map());
	const [language] = useLanguage();

	const translate = (key) => {
		if (language !== defaultLanguage && dictionary.has(key)) {
			return dictionary.get(key);
		}
		return key;
	};

	const mapMenu = (item) => {
		return {
			...item,
			path: item.path && !/^\//.test(item.path) ? `/${item.path}` : item.path,
			name: language !== defaultLanguage && item.name_en ? item.name_en : item.name,
			items: item.items && item.items.length ? item.items.map(mapMenu) : item.items,
		};
	};

	const menuItems = useMemo(() => {
		if (user && user.menus) {
			return user.menus.map(mapMenu);
		} else {
			return [];
		}
	}, [user]);

	useEffect(() => {
		if (user && user.id > 0) {
			handleReloadTruong();
		}
	}, [user]);

	const handleReloadTruong = async () => {
		const dm_truong_ids = user.campus ? user.campus.map((x) => x.id) : [];
		const res = await truongApi.SelectAll();
		if (res.is_success) {
			const dm_truongs = res.data.filter((x) => dm_truong_ids.includes(x.id));
			dispatch({ type: truongActionTypes.LOAD.SUCCESS, payload: dm_truongs });
		}
	};

	useEffect(() => {
		if (user && user.id > 0) {
			handleReloadDictionary();
		}
	}, [user]);

	const handleReloadDictionary = async () => {
		const res = await tsDictionaryApi.selectAll();
		if (res.is_success) {
			const dictionaryMap = res.data.reduce((dictionaryMap, element) => {
				dictionaryMap.set(element.vn, element.en);
				return dictionaryMap;
			}, new Map());
			setDictionary(dictionaryMap);
		}
	};

	const canAccessTo = (api, httpMethod) => {
		// return true;
		return user.apis
			.filter((x) => x.method == httpMethod)
			.find((x) => x.url.toUpperCase() === api.toUpperCase() || x.url.toUpperCase() === ('api/' + api).toUpperCase());
	};

	const showNotify = ({ text, type = 'info' | 'warning' | 'success' | 'error' | 'default', time = 3000 }) => {
		notify(translate(text), type, time);
	};

	const store = {
		menuItems,
		translate,
		showNotify,
		canAccessTo,
	};

	return <CommonContext.Provider value={store}>{children}</CommonContext.Provider>;
};

export const useCommonContext = () => useContext(CommonContext);
