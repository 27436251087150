import appInfo from '../app-info';
import { apiHelper, call_post_api } from './api_helper';
export async function select_all(nam_hoc, dm_trangthaihocsinh_ids, searchText, dm_truong_id) {
	return call_post_api(appInfo.api_url + '/hocsinh/select_all', {
		nam_hoc,
		dm_trangthaihocsinh_ids,
		searchText,
		dm_truong_id: dm_truong_id || 0,
	});
}

export async function select_not_in_ace_chi_tiet(data) {
	return call_post_api(appInfo.api_url + '/hocsinh/select_not_in_ace_chi_tiet', data);
}

export async function select_by_id(id) {
	return call_post_api(appInfo.api_url + '/hocsinh/select_by_id', id);
}

export async function hoc_sinh_select_by_id(id) {
	return call_post_api(appInfo.api_url + '/hocsinh/select_by_id', id);
}

export async function hoc_sinh_select_ace_by_id(data) {
	return call_post_api(appInfo.api_url + '/hocsinh/select_ace_by_id', data);
}
export async function insert(data) {
	return call_post_api(appInfo.api_url + '/hocsinh/insert', data);
}

export async function update(data) {
	return call_post_api(appInfo.api_url + '/hocsinh/update', data);
}

export async function remove(data) {
	return call_post_api(appInfo.api_url + '/hocsinh/delete', data);
}
export async function approve(data) {
	return call_post_api(appInfo.api_url + '/hocsinh/approve', data);
}

export const HOCSINH_API_ENDPOINT = 'hoc-sinh';
export const API_HOCSINH_SELECT_ENDPOINT = 'hoc-sinh/select';
export const API_HOCSINH_SELECT_EXPORT_ENDPOINT = 'hoc-sinh/select-export';
export const API_HOCSINH_CHANGE_STATUS_ENDPOINT = 'hoc-sinh';
export const HOCSINH_API_EDIT_MA_ENDPOINT ='api/hoc-sinh/ma/{ma}/co-so/{dm_coso_id}';
export const hocSinhApi = {
	SelectByParent: (ts_phuhuynh_id) => apiHelper.get(`phu-huynh/${ts_phuhuynh_id}/hoc-sinh`),
	SelectParents: (data) => apiHelper.post(`hoc-sinh/phu-huynh/select`,data),
	Search: (data) => apiHelper.post(`${HOCSINH_API_ENDPOINT}/search`, data),
	Select: (data) => apiHelper.post(API_HOCSINH_SELECT_ENDPOINT, data),
	SelectToExport: (data) => apiHelper.post(API_HOCSINH_SELECT_EXPORT_ENDPOINT, data),
	Detail: (id, nam_hoc) => apiHelper.get(`${HOCSINH_API_ENDPOINT}/${id}/nam-hoc/${nam_hoc}`),
	Insert: (data) => apiHelper.post(`${HOCSINH_API_ENDPOINT}`, data),
	Update: (data) => apiHelper.put(`${HOCSINH_API_ENDPOINT}`, data),
	UpdateStatus: (data) => apiHelper.put(`${HOCSINH_API_ENDPOINT}/status`, data),
	UpdateNgayNhapHoc: (data) => apiHelper.put(`${HOCSINH_API_ENDPOINT}/ngay-nhap-hoc`, data),
	Deletes: (data) => apiHelper.put(`${HOCSINH_API_ENDPOINT}/deletes`, data),
	Import: (data,nam_hoc, dm_coso_id) => apiHelper.put(`${HOCSINH_API_ENDPOINT}/import/nam-hoc/${nam_hoc}/co-so/${(dm_coso_id ?? 0)}`, data),
	ValidateImport: (data, nam_hoc, dm_coso_id) => apiHelper.put(`${HOCSINH_API_ENDPOINT}/validate-import/nam-hoc/${nam_hoc}/co-so/${(dm_coso_id ?? 0)}`, data),
	SelectSuggestEmail: (ts_hocsinh_id) => apiHelper.get(`hoc-sinh/select_suggest_email/${ts_hocsinh_id}`),
	SelectByMaHs: (ma_hs, dm_coso_id) => apiHelper.get(`${HOCSINH_API_ENDPOINT}/ma/${ma_hs}/co-so/${(dm_coso_id ?? 0)}`),
	ImportLenLop: (data,nam_hoc, dm_coso_id) => apiHelper.put(`${HOCSINH_API_ENDPOINT}/import-len-lop/nam-hoc/${nam_hoc}/co-so/${(dm_coso_id ?? 0)}`, data),
	ValidateImportLenLop: (data, nam_hoc, dm_coso_id) => apiHelper.put(`${HOCSINH_API_ENDPOINT}/validate-import-len-lop/nam-hoc/${nam_hoc}/co-so/${(dm_coso_id ?? 0)}`, data),
	SelectPhuHuynh: (id, dm_quanhe_id) => apiHelper.get(`${HOCSINH_API_ENDPOINT}/id/${id}/quanhe/${dm_quanhe_id}`),
};