import React, { useState } from 'react';
import { uploadApi } from '../../api/uploadApi';
import { DefaultButton } from '../editor/button';
import './Upload.css';
export default ({ onSuccess, onError }) => {
  const [file, set_file] = useState();
  const [file_name, set_file_name] = useState();
  const [is_loading, set_is_loading] = useState(false);
  const save_file = (e) => {
    set_file(e.target.files[0]);
    set_file_name(e.target.files[0].name);
  };
  const upload_file = async (e) => {
    set_is_loading(true);
    const form_data = new FormData();
    form_data.append('form_file', file);
    form_data.append('file_name', file_name);
    const res = await uploadApi.UploadImg(form_data);
    set_is_loading(false);
    if (res.is_success) {
      onSuccess(res.data.url);
    } else {
      onError(res.message);
    }
  };
  return (
    <React.Fragment>
      <input
        type="file"
        className="custom-file-input"
        onChange={save_file}
      ></input>
      {file_name && (
        <DefaultButton
          isLoading={is_loading}
          onClick={upload_file}
          icon="fas fa-file-upload"
          text={`Upload file (${file_name})`}
        ></DefaultButton>
      )}
    </React.Fragment>
  );
};
