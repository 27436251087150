import appInfo from "../app-info";
import { apiHelper, call_post_api } from "./api_helper";

export const HE_API_ENDPOIT = 'he';
export const heApi = {
  SelectAll: () => apiHelper.get(HE_API_ENDPOIT),
  SelectByTruong: (dm_truong_id) => apiHelper.get(`truong/${dm_truong_id}/he`),
  SelectByKhoi: (dm_khoi_id) => apiHelper.get(`khoi/${dm_khoi_id}/he`),
  Insert: (data) => apiHelper.post(HE_API_ENDPOIT, data),
  Update: (data) => apiHelper.put(HE_API_ENDPOIT, data),
  Delete: (id) => apiHelper.delete(`${HE_API_ENDPOIT}/${id}`),
  Detail: (id) => apiHelper.get(`${HE_API_ENDPOIT}/${id}`),
}