import { useEffect, useState } from 'react';

const keyName = 'language';
export const defaultLanguage = 'vn';

const createInitialLanguage = () => {
	let initialLanguage = defaultLanguage;
	const language = localStorage.getItem(keyName);
	if (language) {
		initialLanguage = language;
	}
	return initialLanguage;
};

const useLanguage = () => {
	const [language, setLanguage] = useState(createInitialLanguage);

	useEffect(() => {
		const language = localStorage.getItem(keyName);
		if (language) {
			setLanguage(language);
		}
	}, []);

	useEffect(() => {
		localStorage.setItem(keyName, language);
	}, [language]);

	return [language, setLanguage];
};

export default useLanguage;
