import appInfo from '../app-info';
import { apiHelper, call_post_api } from './api_helper';
export async function dm_khoi_select_by_truong(id) {
    return call_post_api(appInfo.api_url + '/khoi/select_by_truong_id', id);
};
export async function select_by_truong(id) {
    return call_post_api(appInfo.api_url + '/khoi/select_by_truong_id', id);
};
export async function select_all() {
    return call_post_api(appInfo.api_url + '/khoi/select_all');
};
export async function select_by_id(id) {
    return call_post_api(appInfo.api_url + '/khoi/select_by_id', id);
};
export async function insert(data) {
    return call_post_api(appInfo.api_url + '/khoi/insert', data);
}

export async function update(data) {
    return call_post_api(appInfo.api_url + '/khoi/update', data);
}

export async function remove(id) {
    return call_post_api(appInfo.api_url + '/khoi/delete', id);
}

export const delete_he_by_khoi_id = async (data) => {
    return call_post_api(appInfo.api_url + '/khoi/delete_he_by_khoi_id', data);
};

export const update_he_by_khoi_id = async (data) => {
    return call_post_api(appInfo.api_url + '/khoi/update_he_by_khoi_id', data);
};

export async function select_all_truong_khoi_he() {
    return call_post_api(appInfo.api_url + '/khoi/select_all_truong_khoi_he');
};

export async function select_all_truong_khoi_he_by_loaigiayto(id) {
    return call_post_api(appInfo.api_url + '/khoi/select_all_truong_khoi_he_by_loaigiayto', id);
};
export const KHOI_API_ENDPOINT = "khoi";
export const khoiApi = {
    SelectAll: () => apiHelper.get(KHOI_API_ENDPOINT),
    SelectByTruong: (dm_truong_id) => apiHelper.get(`truong/${dm_truong_id}/khoi`),
    Insert: (data) => apiHelper.post(KHOI_API_ENDPOINT, data),
    Update: (data) => apiHelper.put(KHOI_API_ENDPOINT, data),
    Delete: (id) => apiHelper.delete(`${KHOI_API_ENDPOINT}/${id}`),
    Detail: (id) => apiHelper.get(`${KHOI_API_ENDPOINT}/${id}`),
}