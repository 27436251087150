import { combineReducers } from "@reduxjs/toolkit"
import { authReducer } from "./authReducer"
import { truongReducer } from "./truongReducer"
import { coSoReducer } from "./coSoReducer"
import { namHocReducer } from "./namHocReducer"
export const reducers = combineReducers({
    auth: authReducer,
    truong: truongReducer,
    coSo: coSoReducer,
    namHoc: namHocReducer,
})