import appInfo from "../app-info";
import { apiHelper, call_post_api } from "./api_helper";

export const FORMEDITORAPI_ENDPOINT = "form-editor";


export const formEditorApi = {
    SelectAll: () => apiHelper.get(`${FORMEDITORAPI_ENDPOINT}`),
    SelectByCoSo: (dm_coso_id) => apiHelper.get(`${FORMEDITORAPI_ENDPOINT}/coso/${dm_coso_id}`),
    SelectByIdTruong: (dm_truong_id) => apiHelper.get(`${FORMEDITORAPI_ENDPOINT}/dm_truong_id/${dm_truong_id}`),
    SelectDataSource: (id, dm_coso_id, parent_id) => apiHelper.getWithoutCheckToken(`${FORMEDITORAPI_ENDPOINT}/datasource/${id}/coso/${dm_coso_id}/parentid/${parent_id}`),
    Insert: (data) => apiHelper.post(`${FORMEDITORAPI_ENDPOINT}`, data),
    Update: (data) => apiHelper.put(`${FORMEDITORAPI_ENDPOINT}`, data),
    Delete: (id) => apiHelper.delete(`${FORMEDITORAPI_ENDPOINT}/${id}`),
    Detail: (id) => apiHelper.get(`${FORMEDITORAPI_ENDPOINT}/${id}`),
}