import { pageBaseStatus } from "../pageBaseStatus";
import { actionNameCreator } from "../state-helpers/actionTypeNameCreator";
export const iniState = {
    status: pageBaseStatus.is_not_initial,
    nam_hocs: [],
    nam_hoc: localStorage.getItem("header_namhoc") ? localStorage.getItem("header_namhoc") : ""
}
export const namHocActionTypes = {
    LOAD: actionNameCreator.NAMHOC("LOAD"),
    CHANGE_SELECTED_ID: "NAM_HOC_CHANGE_SELECTED_ID",
}
export const namHocReducer = (state = iniState, action) => {
    switch (action.type) {
        case namHocActionTypes.LOAD.START:
            return {
                ...state,
                status: pageBaseStatus.is_loading,
                nam_hocs: []
            }
        case namHocActionTypes.LOAD.SUCCESS:
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                nam_hocs: action.payload
            }
        case namHocActionTypes.LOAD.ERROR:
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                nam_hocs: []
            }
        case namHocActionTypes.CHANGE_SELECTED_ID:
            // console.log(action.payload);
            localStorage.setItem("header_namhoc", action.payload)
            return {
                ...state,
                nam_hoc: action.payload
            };
        default:
            return state;
    }
}
