import { apiHelper } from './api_helper';

export const CHIEN_DICH_API_END_POINT = "chien-dich";
export const chienDichApi = {
  SelectAll: () => apiHelper.get(`${CHIEN_DICH_API_END_POINT}`),
  SelectByCoSo: (dm_coso_id) => apiHelper.get(`co-so/${dm_coso_id}/chien-dich`),
  Select: (nam_hoc) => apiHelper.get(`${CHIEN_DICH_API_END_POINT}`),
  Detail: (id) => apiHelper.get(`${CHIEN_DICH_API_END_POINT}/${id}`),
  Insert: (data) => apiHelper.post(`${CHIEN_DICH_API_END_POINT}`, data),
  Update: (data) => apiHelper.put(`${CHIEN_DICH_API_END_POINT}`, data),
  Delete: (id) => apiHelper.delete(`${CHIEN_DICH_API_END_POINT}/${id}`),
  Lock: (id) => apiHelper.put(`${CHIEN_DICH_API_END_POINT}/lock/${id}`),
  UnLock: (id) => apiHelper.put(`${CHIEN_DICH_API_END_POINT}/unlock/${id}`),
  SelectTruongByChienDich: (dm_chiendich_id) => apiHelper.get(`chien-dich/${dm_chiendich_id}/truong`),
}